import { useCallback, useRef } from 'react';
import { THEME } from '../../constants';
import { useTransitionCycle, useInputHandler } from '../../../../../../hooks';

const useHero = ({ slides, interval, transitionDuration, onBrandNameSubmit }) => {
    const { inputRef, onSubmit, onEnter } = useInputHandler({
        handleSubmit: onBrandNameSubmit
    });
    const wrapperRef = useRef();
    const { index = 0, isInTransition } = useTransitionCycle({
        size: slides.length,
        interval,
        transitionDuration
    });

    const slide = slides[index];

    const onScrollDown = useCallback(() => {
        if (wrapperRef.current) {
            const { offsetTop, offsetHeight } = wrapperRef.current;
            window.scroll({
                top: offsetTop + offsetHeight,
                behavior: 'smooth'
            });
        }
    }, []);

    return {
        slide,
        isInTransition,
        onEnter,
        onSubmit,
        onScrollDown,
        inputRef,
        wrapperRef,
        isDarkTheme: slide.theme === THEME.DARK
    };
};

export default useHero;
