const LOGO_SIZE = {
    width: 228,
    height: 228
};

const getAlt = (i) => `makers_experience_perseus.buyer_landing_page.get_inspired.variation_${i + 1}.alt`;

export const LOGOS = [
    {
        image: '9f1bab37da9edd2acdd3fbfa386bd8c7-1652447125634/logo-1.png',
        author: 'artphysis',
        alt: getAlt(0),
        size: LOGO_SIZE
    },
    {
        image: 'f7635b66c121cda2adacc1ccc218cf73-1652447138259/logo-2.png',
        author: 'tariklogodesign',
        alt: getAlt(1),
        size: LOGO_SIZE
    },
    {
        image: '446475c497e5652bd57241b0eb3a56f4-1652447147248/logo-3.png',
        author: 'umaurus',
        alt: getAlt(2),
        size: LOGO_SIZE
    },
    {
        image: '70135c2d47b4a4892897524eb00e6a9a-1652447155030/logo-4.png',
        author: 'trag_design',
        alt: getAlt(3),
        size: LOGO_SIZE
    },
    {
        image: 'bb298b24dbbb39af6d81a1287ddbad82-1652447163647/logo-5.png',
        author: 'yuvall',
        alt: getAlt(4),
        size: LOGO_SIZE
    },
    {
        image: 'b0040ba787bd2afa36fde04371bd7801-1652447171595/logo-6.png',
        author: 'zlannan',
        alt: getAlt(5),
        size: LOGO_SIZE
    },
    {
        image: '919f3a89cbba6be120676d3ee9a64323-1652447178909/logo-7.png',
        author: 'iiidddooo',
        alt: getAlt(6),
        size: LOGO_SIZE
    },
    {
        image: '2577de227cc70c34b4efa789a629233a-1652447184860/logo-8.png',
        author: 'clevertypo',
        alt: getAlt(7),
        size: LOGO_SIZE
    },
    {
        image: '07097ed1642ab0edbd677771ee7f3f7a-1652447192123/logo-9.png',
        author: 'xushxush',
        alt: getAlt(8),
        size: LOGO_SIZE
    },
    {
        image: 'fe76cdab873c727580dd133a6a6b1e53-1652447199949/logo-10.png',
        author: 'amitmaman',
        alt: getAlt(9),
        size: LOGO_SIZE
    }
];
