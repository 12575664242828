import { useRef, useEffect } from 'react';

const useEffectOnce = (callback) => {
    const hasRunRef = useRef(false);
    useEffect(() => {
        if (hasRunRef.current) {
            return;
        }

        hasRunRef.current = true;
        return callback();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useEffectOnce;
