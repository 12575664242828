import { merge } from 'lodash';
import { bigQuery } from '@fiverr-private/obs';
import {
    EVENT_TYPES,
    EVENTS,
    TRACKING_STATIC_CONTEXT,
    ENRICHABLE_EVENTS,
    SOURCE_TO_TRACKING_PAGE_NAME_MAP
} from './constants';

export const getBigQueryPageViewEventType = (appName, route) => {
    if (!route) {
        return `logo_maker_${appName}_show`;
    }

    return `logo_maker_${appName}_${route}_show`;
};

const prepareBigQueryPayload = (event, context, payload = {}, withSource = true) => {
    const { refCtxId, source, ...restOfContext } = context;
    const pageReferrer = {
        page: {
            referrer: {
                ctx_id: refCtxId,
                ...(withSource && {
                    name: SOURCE_TO_TRACKING_PAGE_NAME_MAP[source] || source
                })
            }
        }
    };

    return merge({}, restOfContext, event, payload, pageReferrer);
};

/**
 * Tracking class util, Used to track client events.
 */
export class Monitor {
    initialize({ appName, ...trackingContext }) {
        if (!appName) {
            throw Error("Tracker was initialized without appName. Please check your application's server entry.");
        }

        this.appName = appName;
        this._context = { ...TRACKING_STATIC_CONTEXT, ...trackingContext };
    }

    get context() {
        return this._context || {};
    }

    /**
     * Updates current context dynamically.
     * @param {Object} update - new data
     */
    updateContext(update) {
        this._context = merge({}, this.context, update);
    }

    /**
     * Tracks a given event into proper platforms
     * @param {String} type - The event type to report.
     * @param {Object} payload - Enrichment
     * @see TrackerEvent
     */
    track(type, payload) {
        const event = EVENTS[type];

        if (event) {
            const combinedPayload = prepareBigQueryPayload({ type, ...event }, this.context, payload, false);

            bigQuery.send(combinedPayload);
        }
    }

    /**
     * Tracks an array of given events into bigQuery.
     * @param {Array} events.
     * @param {String} events.type - The event type to report.
     * @param {Object} events.payload - Enrichment
     * @see TrackerEvent
     */
    multitrack(events) {
        events
            .map(({ type, payload }) => {
                const event = EVENTS[type];
                if (event) {
                    return prepareBigQueryPayload({ type, ...event }, this.context, payload, false);
                }
                return null;
            })
            .filter((event) => !!event)
            .forEach((event) => bigQuery.send(event));
    }

    /**
     * Provides an enrichment of current context for events
     * that are created & reported by other services).
     * @param {String} type
     * @param {String} [platform]
     * @return {Object}
     */
    enrich(type, platform) {
        return platform
            ? {
                [platform]: {
                    ...ENRICHABLE_EVENTS[type],
                    ...this.context
                }
            }
            : {
                ...ENRICHABLE_EVENTS[type],
                ...this.context
            };
    }

    /**
     * Tracks a page view event.
     * @param {{
     *   route: String
     *   pageName: String,
     *   httpRequest: { url: String, referrer: String } },
     *   traffic: Object
     * } [config]
     * @see TrackerEvent
     */
    trackPageView({ route, pageName, pageElementType, httpRequest, traffic } = {}) {
        const event = {
            type: getBigQueryPageViewEventType(this.appName, route),
            http_request: httpRequest,
            traffic_source: traffic,
            ...(pageName && { page: { name: pageName } }),
            ...(pageElementType && {
                page: { element: { type: pageElementType } }
            }),
            ...EVENTS[EVENT_TYPES.PAGE_VIEW]
        };

        const payload = prepareBigQueryPayload(event, this.context);
        bigQuery.send(payload);
    }
}
