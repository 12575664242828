import pathfinder from '@fiverr-private/pathfinder';
import { breakpoints, breakpointReverses } from '@fiverr-private/sass/helpers';

const PREFIX = 'makers_experience_perseus.buyer_landing_page.where_should_you_use';

export const CONTENT = [
    {
        title: `${PREFIX}.answer_1.title`,
        body: `${PREFIX}.answer_1.body`,
        createLink: () =>
            pathfinder(
                'short_category_sub_category',
                { category_id: 'graphics-design', id: 'social-media-design' },
                { query: { source: 'logo-maker-mp' } }
            )
    },
    {
        title: `${PREFIX}.answer_2.title`,
        body: `${PREFIX}.answer_2.body`,
        createLink: () =>
            pathfinder(
                'short_category_nested_sub_category',
                {
                    category_id: 'programming-tech',
                    id: 'web-cms-services',
                    nested_sub_category_id: 'full-website-creation'
                },
                { query: { source: 'logo-maker-mp' } }
            )
    },
    {
        title: `${PREFIX}.answer_3.title`,
        body: `${PREFIX}.answer_3.body`,
        createLink: () =>
            pathfinder(
                'short_category_sub_category',
                { category_id: 'graphics-design', id: 'email-design' },
                { query: { source: 'logo-maker-mp' } }
            )
    },
    {
        title: `${PREFIX}.answer_4.title`,
        body: `${PREFIX}.answer_4.body`,
        createLink: () =>
            pathfinder(
                'short_category_sub_category',
                {
                    category_id: 'graphics-design',
                    id: 'sample-business-cards-design'
                },
                { query: { source: 'logo-maker-mp' } }
            )
    },
    {
        title: `${PREFIX}.answer_5.title`,
        body: `${PREFIX}.answer_5.body`,
        createLink: () =>
            pathfinder(
                'short_category_sub_category',
                { category_id: 'graphics-design', id: 't-shirts' },
                { query: { source: 'logo-maker-mp' } }
            )
    },
    {
        title: `${PREFIX}.answer_6.title`,
        body: `${PREFIX}.answer_6.body`,
        createLink: () => pathfinder('tag_page', { id: 'promotional-design' }, { query: { source: 'logo-maker-mp' } })
    }
];

export const IMAGE = '5b2b30011e99290c6535e4886dd69511-1652351794820/logo-maker-landing-page-where-to-use.png';

export const IMAGE_RESOLUTIONS = [
    {
        media: `(max-width: ${breakpoints.xs}px)`,
        config: { width: 320, height: 405 }
    },
    {
        media: `(max-width: ${breakpoints.sm}px)`,
        config: { width: 600, height: 643 }
    },
    {
        media: `(max-width: ${breakpoints.md}px)`,
        config: { width: 720, height: 772 }
    },
    {
        media: `(max-width: ${breakpointReverses.lg}px)`,
        config: { width: 960, height: 585 }
    },
    {
        media: `(min-width: ${breakpoints.lg}px)`,
        config: { width: 584 }
    }
];
