export const NEW_LOGO_BUTTONS_POSITIONS = {
    MIDDLE: 'middle',
    TOP: 'top_page'
};

export const NEW_LOGO_BUTTONS_NAMES = {
    MAKE_NEW_LOGO: 'make_new_logo',
    MAKE_A_LOGO_YOU_LOVE: 'make_a_logo_you_love',
    MAKE_YOUR_LOGO: 'make_your_logo'
};

export const COMPONENT_NAMES = {
    MY_FAVORITES: 'my_favorites',
    MY_DESIGNS: 'my_designs',
    AVATAR: 'avatar'
};

export const AVATAR_PROPS = {
    elementName: NEW_LOGO_BUTTONS_NAMES.MAKE_NEW_LOGO,
    component: COMPONENT_NAMES.AVATAR
};
