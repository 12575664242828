import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import LandingPageStepSection from '../../LandingPageStepSection';
import { STEPS, INTERVAL } from './constants';
import classes from './index.module.scss';

const HowDoesLogoMakerWork = () => (
    <section className={classes.wrapper}>
        <div className={classes.container}>
            <h2 className={classes.title}>
                <I18n k="makers_experience_perseus.buyer_landing_page.how_does_it_work.section_title"/>
            </h2>
            <LandingPageStepSection steps={STEPS} interval={INTERVAL}/>
        </div>
    </section>
);

export default HowDoesLogoMakerWork;
