import { breakpoints } from '@fiverr-private/sass/helpers';

export const IMAGES_RESOLUTIONS = [
    {
        media: `(min-width: ${breakpoints.lg}px)`,
        config: { width: 310, height: 174 }
    },
    {
        media: `(min-width: ${breakpoints.sm}px)`,
        config: { width: 300, height: 174 }
    },
    {
        media: `(min-width: ${breakpoints.xs}px)`,
        config: { width: 550, height: 415 }
    }
];
