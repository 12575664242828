import React from 'react';
import { array, number } from 'prop-types';
import cn from 'classnames';
import { I18n, translate } from '@fiverr-private/i18n-react';
import useAnimatedCycle from '../../../hooks/useTransitionCycle';
import CloudinaryResponsiveImage from '../../CloudinaryResponsiveImage';
import { DEFAULT_INTERVAL, RESPONSIVE_IMAGES, TRANSITION_DURATION } from './constants';
import classes from './index.module.scss';

const LandingPageStepSection = ({ steps, interval = DEFAULT_INTERVAL }) => {
    const { index, setIndex, isInTransition } = useAnimatedCycle({
        size: steps.length,
        interval,
        transitionDuration: TRANSITION_DURATION
    });

    return (
        <div className={classes.wrapper}>
            <div className={classes.steps}>
                {steps.map((s, i) => (
                    <div key={s.title}
                        className={cn(classes.step, {
                            [classes.currentStep]: i === index
                        })}
                        onClick={() => setIndex(i)}>
                        {i + 1}
                    </div>
                ))}
            </div>
            <div className={cn(classes.textBlock, {
                [classes.inTransition]: isInTransition
            })}>
                {steps.map(({ step, title, description }, i) => (
                    <div key={step}
                        className={cn(classes.textStep, { [classes.hidden]: index !== undefined && i !== index })}>
                        <small className={classes.stepName}>
                            <I18n k={step}/>
                        </small>
                        <h3 className={classes.title}>
                            <I18n k={title}/>
                        </h3>
                        {description.map((description, i) => (
                            <p className={classes.description} key={i}>
                                <I18n k={description}/>
                            </p>
                        ))}
                    </div>
                ))}
            </div>
            <div className={cn(classes.illustration, {
                [classes.inTransition]: isInTransition
            })}>
                {steps.map(({ illustration, alt }, i) => (
                    <CloudinaryResponsiveImage key={i}
                        className={cn(classes.illustrationImage, {
                            [classes.hidden]: i !== index
                        })}
                        mediaQueryGrid={RESPONSIVE_IMAGES}
                        image={illustration.src}
                        width={illustration.size?.width}
                        height={illustration.size?.height}
                        alt={translate(alt)}/>
                ))}
            </div>
        </div>
    );
};

LandingPageStepSection.propTypes = {
    steps: array,
    interval: number
};

export default LandingPageStepSection;
