import { breakpoints } from '@fiverr-private/sass/helpers';

export const getCloudinaryImages = (image) => [
    {
        media: `(min-width: ${breakpoints.md}px)`,
        image,
        config: { width: breakpoints.lg }
    },
    {
        media: `(min-width: ${breakpoints.sm}px)`,
        image,
        config: { width: breakpoints.md }
    },
    {
        media: `(min-width: ${breakpoints.xs}px)`,
        image,
        config: { width: breakpoints.sm }
    }
];
