(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("classnames"), require("lodash"), require("lodash/get"), require("lodash/noop"), require("prop-types"), require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["classnames", "lodash", "lodash/get", "lodash/noop", "prop-types", "react", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["perseusApp"] = factory(require("classnames"), require("lodash"), require("lodash/get"), require("lodash/noop"), require("prop-types"), require("react"), require("react-dom"));
	else
		root["perseusApp"] = factory(root["classNames"], root["_"], root["_"]["get"], root["_"]["noop"], root["PropTypes"], root["React17"], root["ReactDOM17"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_IX_1__, __WEBPACK_EXTERNAL_MODULE_luq0__, __WEBPACK_EXTERNAL_MODULE_N27b__, __WEBPACK_EXTERNAL_MODULE_poII__, __WEBPACK_EXTERNAL_MODULE_JjgO__, __WEBPACK_EXTERNAL_MODULE_yHGu__, __WEBPACK_EXTERNAL_MODULE__4LQx__) => {
return 