import React from 'react';
import { array } from 'prop-types';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { Collapsible } from '@fiverr-private/fit';
import { makersExperience } from '@fiverr-private/logo_maker_analytics/dist/esm/mixpanel';
import classes from './style.module.scss';

const { reportFAQClicked } = makersExperience.buyerLandingPage;

const Faq = ({ content = [] }) => (
    <section className={classes.wrapper}>
        <div className={classes.container}>
            <h2 className={classes.title}>
                <I18n k="makers_experience_perseus.buyer_landing_page.faq.section_title"/>
            </h2>
            <div>
                {content.map(({ question, answer, link }) => (
                    <Collapsible key={question}
                        className={classes.collapsible}
                        titleWrapperClassName={classes.collapsibleTitleWrapper}
                        title={<h3 className={classes.questionTitle}>{translate(question)}</h3>}
                        onExpand={() => reportFAQClicked({ question: translate(question) })}>
                        <I18n k={answer}
                            templates={{
                                link: (text) => (
                                    <a className={classes.answerLink} target="_blank" href={link} rel="noreferrer">
                                        {text}
                                    </a>
                                )
                            }}/>
                    </Collapsible>
                ))}
            </div>
        </div>
    </section>
);

Faq.propTypes = {
    content: array
};

export default Faq;
