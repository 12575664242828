import React from 'react';
import { number, string } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import classes from './index.module.scss';

const Title = ({ index, text }) => (
    <h3 className={classes.title}>
        <span className={classes.number}>{index}</span>
        <I18n k={text}/>
    </h3>
);

Title.propTypes = {
    index: number,
    text: string
};

export default Title;
