import React from 'react';
import { makersExperience } from '@fiverr-private/logo_maker_analytics/dist/esm/mixpanel';
import monitor from '../../../service/bigquery';
import topLevelSource from '../../../shared/storage/topLevelSource';
import getQueryParamFromSearch from '../../../shared/lib/getQueryParamFromSearch';
import { type TrackingData } from '../../../shared/lib/getTrackingData';
import { type UserAgentData } from '../../../shared/lib/getUserAgent';
import { UserAgentContext } from '../../../shared/components/UserAgentContext';
import { useOnceBeforeRender } from '../../../shared/hooks';
import { BriefIdContextProvider } from '../../../shared/components/BriefIdContext';
import BuyerLandingPage from '../../components/BuyerLandingPage/BuyerLandingPage';

const { store: mixpanelStore } = makersExperience.buyerLandingPage;

interface ClientBuyerLandingPageProps {
    userAgent: UserAgentData;
    industriesTags: string[];
    reviewsStatistics: {
        averageRating: number,
        totalReviews: number,
    };
    tracking: TrackingData & {
        makers_project: { is_boost: 0 | 1 },
    };
    briefId: string;
}

export default function ClientBuyerLandingPage({
    userAgent,
    industriesTags,
    reviewsStatistics,
    tracking,
    briefId
}: ClientBuyerLandingPageProps) {
    useOnceBeforeRender(() => {
        mixpanelStore.fromBoost.setData(tracking.makers_project.is_boost);
        mixpanelStore.pageType.setData(topLevelSource.SOURCES.LANDING_PAGE);

        monitor.initialize(tracking);

        const source = getQueryParamFromSearch('source', window.location.search) || topLevelSource.SOURCES.LANDING_PAGE;

        // Set mark in the localStorage to mark a user as they came from the Makers landing page
        topLevelSource.setTopLevelSourceInStorage(source);
    });

    return (
        <UserAgentContext.Provider value={userAgent}>
            <BriefIdContextProvider value={briefId}>
                <BuyerLandingPage industriesTags={industriesTags} reviewsStatistics={reviewsStatistics}/>
            </BriefIdContextProvider>
        </UserAgentContext.Provider>
    );
}
