import { useState, useRef } from 'react';

const useVimeoVideo = () => {
    const [showPreview, setShowPreview] = useState(true);
    const playerIsRequested = useRef(false);
    const playerPromise = useRef();
    const containerRef = useRef();

    const getPlayer = async() => {
        const container = containerRef.current;

        if (container && !playerIsRequested.current) {
            playerIsRequested.current = true;
            playerPromise.current = import(/* webpackChunkName: 'vimeoPlayer' */ '@vimeo/player').then(
                ({ default: Player }) => new Player(container)
            );
        }

        return playerPromise.current;
    };

    const handlePreviewClicked = async() => {
        const player = await getPlayer();

        setShowPreview(false);
        player.play();
    };

    return {
        containerRef,
        showPreview,
        handlePreviewClicked
    };
};

export default useVimeoVideo;
