import { useEffect } from 'react';

const useOnHeaderNewLogoLinkClick = (logoMakerHeaderNewLogoLinkClickHandler) => {
    useEffect(() => {
        const logoMakerHeaderNewLogoLink = document.querySelector('[data-click="logo-maker-header-new-logo-link"]');

        if (logoMakerHeaderNewLogoLink) {
            logoMakerHeaderNewLogoLink.addEventListener('click', logoMakerHeaderNewLogoLinkClickHandler);

            return () =>
                logoMakerHeaderNewLogoLink.removeEventListener('click', logoMakerHeaderNewLogoLinkClickHandler);
        }
    }, [logoMakerHeaderNewLogoLinkClickHandler]);
};

export default useOnHeaderNewLogoLinkClick;
