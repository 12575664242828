import IMAGES from './assets';

export const REASONS_LIST = IMAGES.map((illustration, i) => {
    const copySlug = `makers_experience_perseus.buyer_landing_page.why_use_logo_maker.value_${i + 1}`;

    return {
        title: `${copySlug}.title`,
        alt: `${copySlug}.alt`,
        body: `${copySlug}.body`,
        illustration
    };
});
