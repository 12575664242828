import { useState, useEffect, useCallback, useRef } from 'react';

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const useTransitionCycle = ({ size, interval, transitionDuration = 0 }) => {
    const [index, setIndexState] = useState();
    const isInCycle = useRef(true);
    const [isInTransition, setIsInTransition] = useState(false);

    useEffect(() => {
        setIndexState(0);

        const cycleCallback = async() => {
            if (isInCycle.current) {
                await wait(interval - transitionDuration);
                setIsInTransition(true);
            }
            if (isInCycle.current) {
                await wait(transitionDuration);
                setIndexState((i) => (i < size - 1 ? i + 1 : 0));
            }
            setIsInTransition(false);
            cycleCallback();
        };

        cycleCallback();
    }, [size, interval, transitionDuration]);

    const setIndex = useCallback((index) => {
        setIndexState(index);
        isInCycle.current = false;
    }, []);

    return { index, setIndex, isInTransition };
};

export default useTransitionCycle;
