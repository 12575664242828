import React, { useMemo } from 'react';
import { string, number } from 'prop-types';
import classnames from 'classnames';
import pathfinder from '@fiverr-private/pathfinder';
import { numberFormat } from '@fiverr-private/localization';
import { translate } from '@fiverr-private/i18n-react';
import { ArrowRightIcon } from '@fiverr-private/icons';
import ClickableRating from '../../ClickableRating';
import classes from './index.module.scss';

const COPY_PREFIX = 'makers_experience_perseus.buyer_landing_page.header.rating';

const MIN_RATINGS_FOR_SCORE = 10;
const MIN_RATINGS_FOR_COUNT = 900;

/**
 * @param {{
 *  className?: string,
 *  rating?: number,
 *  ratingsCount?: number;
 * }} props
 */
const TotalRating = ({ className, rating = 0, ratingsCount = 0 }) => {
    const formattedRating = useMemo(
        () =>
            numberFormat({
                value: rating,
                options: {
                    fallbackValue: rating,
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1
                }
            }),
        [rating]
    );
    const formattedRatingsCount = useMemo(
        () =>
            numberFormat({
                value: ratingsCount,
                options: {
                    fallbackValue: ratingsCount
                }
            }),
        [ratingsCount]
    );

    if (!rating || ratingsCount < MIN_RATINGS_FOR_SCORE) {
        return null;
    }

    return (
        <div className={classnames(classes.wrapper, className)}>
            <div className={classes.ratingWrapper}>
                <ClickableRating className={classes.rating} rating={rating} readonly/>
                <div className={classes.scoreWrapper}>
                    <span className={classes.score}>{formattedRating}/5</span>
                    {ratingsCount > MIN_RATINGS_FOR_COUNT && (
                        <span className={classes.ratingsCount}>
                            {translate(`${COPY_PREFIX}.from_count_ratings`, {
                                params: { count: formattedRatingsCount }
                            })}
                        </span>
                    )}
                </div>
            </div>
            <a href={pathfinder('logo_maker_testimonials_page')}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}>
                {translate(`${COPY_PREFIX}.check_reviews`)}
                <ArrowRightIcon/>
            </a>
        </div>
    );
};

TotalRating.propTypes = {
    className: string,
    rating: number,
    ratingsCount: number
};

export default TotalRating;
