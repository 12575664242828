import React from 'react';
import { string, array, number } from 'prop-types';
import getCloudinaryImagePath from '../../lib/getCloudinaryImageUrl';
import classes from './index.module.scss';

const getSrcSet = (image, options) => {
    const imageSrc1x = getCloudinaryImagePath(image, options);
    const imageSrc2x = getCloudinaryImagePath(image, options, 2);
    return `${imageSrc1x} 1x, ${imageSrc2x} 2x`;
};

const CloudinaryResponsiveImage = ({ image, alt, mediaQueryGrid = [], className, height, width }) => (
    <picture className={classes.wrapper}>
        {mediaQueryGrid.map(({ media, config = {}, image: sourceImage = image }) => (
            <source media={media}
                key={media}
                srcSet={getSrcSet(sourceImage, config)}
                width={config.width}
                height={config.height}/>
        ))}
        <img src={getCloudinaryImagePath(image)}
            alt={alt}
            className={className}
            loading="lazy"
            width={width}
            height={height}/>
    </picture>
);

CloudinaryResponsiveImage.propTypes = {
    image: string,
    alt: string,
    mediaQueryGrid: array,
    className: string,
    height: number,
    width: number
};

export default CloudinaryResponsiveImage;
