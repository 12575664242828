import { useCallback, useRef } from 'react';
import { keyPressListener, KEYS } from '@fiverr-private/variations_display/src/lib';

const useInputHandler = ({ handleSubmit }) => {
    const inputRef = useRef();

    const onSubmit = useCallback(() => {
        handleSubmit(inputRef.current.value);
    }, [handleSubmit]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onEnter = useCallback(keyPressListener({ [KEYS.ENTER]: onSubmit }), []);

    return {
        inputRef,
        onEnter,
        onSubmit
    };
};

export default useInputHandler;
