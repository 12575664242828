import monitor from '../../../service/bigquery';

export const sendBiEventWithPosition = (pos, eventType) => {
    monitor.track(eventType, {
        page: {
            element: {
                position: pos
            }
        }
    });
};
