import image0 from './assets/Image.png';
import image1 from './assets/plastic-wrap-texture-folded-t-shirt-with-stickers-mockup-template@2x.png';
import image2 from './assets/square-label-tags-mockup-template@2x.png';
import image3 from './assets/top-view-folded-t-shirt-mockup-template@2x.png';

export const THEME = {
    LIGHT: 'light',
    DARK: 'dark'
};

export const BUYER_LP_HERO_SLIDES = [
    { backgroundColor: '#FAE7CF', image: image0, theme: THEME.LIGHT },
    { backgroundColor: '#421300', image: image1, theme: THEME.DARK },
    { backgroundColor: '#042F2F', image: image2, theme: THEME.DARK },
    { backgroundColor: '#BE5272', image: image3, theme: THEME.DARK }
];

export const TRANSITION_DURATION = 250;
export const DEFAULT_INTERVAL = 5000;
