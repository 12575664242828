import React from 'react';
import cn from 'classnames';
import { string, object } from 'prop-types';
import { Icon } from '@fiverr-private/fit';
import { s_play } from '@fiverr-private/icons';
import CloudinaryResponsiveImage from '../CloudinaryResponsiveImage';
import useVimeoVideo from './hooks/useVimeoVideo';
import classes from './index.module.scss';
import { getCloudinaryImages } from './utils/getCloudinaryImages';

const VimeoVideo = ({ video, preview, previewAlt, previewSize = {} }) => {
    const { containerRef, showPreview, handlePreviewClicked } = useVimeoVideo();

    return (
        <div className={classes.wrapper}>
            <div onClick={handlePreviewClicked} className={cn(classes.preview, { [classes.hidden]: !showPreview })}>
                <CloudinaryResponsiveImage alt={previewAlt}
                    image={preview}
                    width={previewSize.width}
                    height={previewSize.height}
                    className={classes.previewImage}
                    mediaQueryGrid={getCloudinaryImages(preview)}/>
                <div className={classes.previewBtn} role="button">
                    <Icon className={classes.previewBtnIcon}>{s_play}</Icon>
                </div>
            </div>
            <div ref={containerRef} className={classes.container} data-vimeo-id={video}/>
        </div>
    );
};

VimeoVideo.propTypes = {
    video: string,
    preview: string,
    previewAlt: string,
    previewSize: object
};

export default VimeoVideo;
