import { useState, useLayoutEffect, useMemo } from 'react';
import { breakpoints } from '@fiverr-private/sass/helpers';

const getIndustriesSizeFromWidth = (width, maxSize) => {
    if (width <= breakpoints.xs) {
        return 9;
    } else if (width <= breakpoints.sm) {
        return 17;
    } else if (width <= breakpoints.md) {
        return 20;
    } else if (width <= breakpoints.lg) {
        return 25;
    } else {
        return maxSize;
    }
};

const useIndustriesIdeas = ({ industries }) => {
    const [industriesSize, setIndustriesSize] = useState();

    useLayoutEffect(() => {
        const listener = () => {
            setIndustriesSize(getIndustriesSizeFromWidth(window.innerWidth, industries.length));
        };
        listener();
        window.addEventListener('resize', listener);
        return () => {
            window.removeEventListener('resize', listener);
        };
    }, [industries]);

    const cuttedIndustries = useMemo(() => industries.slice(0, industriesSize), [industries, industriesSize]);

    return {
        cuttedIndustries
    };
};

export default useIndustriesIdeas;
