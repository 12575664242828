import get from 'lodash/get';
import {
    VIDEO_CONTENT_SRC,
    VIDEO_SRC,
    TRANSLATION_TITLE_KEY,
    VIDEO_THUMBNAIL
} from '../../../shared/components/LandingPages/Sections/HowToMakeLogoVideo/constants';
import getCloudinaryImagePath from '../../../shared/lib/getCloudinaryImageUrl';
import copies from '../../../copies.json';

export const APP_NAME = 'buyer_landing_page';

export const PAGE_TITLE = 'Free Logo Maker | Create Your Logo in 5 Minutes';
export const PAGE_DESCRIPTION =
    'Fiverr online logo maker matches you with beautiful, hand-crafted logos. Get an affordable custom logo and all the branding files you need in just a few minutes!';
const LOGO_MAKER_LOGO =
    'https://fiverr-res.cloudinary.com/image/upload/q_auto,f_png/v1/attachments/generic_asset/asset/d9062a8d899e482795eff725cfd92c94-1577358899413/logo_maker_logo.svg';
const TWITTER_ACCOUNT = '@fiverr';

export const TWITTER_TAGS = {
    card: 'summary',
    title: PAGE_TITLE,
    description: PAGE_DESCRIPTION,
    site: TWITTER_ACCOUNT,
    creator: TWITTER_ACCOUNT,
    image: LOGO_MAKER_LOGO
};

export const OG_TAGS = {
    url: 'https://www.fiverr.com/logo-maker',
    description: PAGE_DESCRIPTION,
    title: PAGE_TITLE,
    image: LOGO_MAKER_LOGO
};

export const SCHEMA = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: get(copies, TRANSLATION_TITLE_KEY),
    description: 'Watch a video on how to make a logo with Fiverr logo maker to create an iconic brand',
    contentUrl: VIDEO_CONTENT_SRC,
    embedUrl: VIDEO_SRC,
    transcript:
        'This is Fiverr Logo Maker, designed to instantly match you with a unique and affordable premade logo. Each logo was crafted by a top-tier designer and is automatically paired and adjusted for you using AI technology. To get started, describe your business, define a look & feel, pick your favorite design, make some adjustments if you’d like, and… that’s about it. Explore the Fiverr logo maker for free and create an iconic brand in about 5 minutes. Or just play around with it. It’s actually pretty fun.',
    thumbnailUrl: getCloudinaryImagePath(VIDEO_THUMBNAIL),
    uploadDate: '2022-05-03',
    duration: 'PT0M41S'
};

export const FAQ_SCHEMA = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
        {
            '@type': 'Question',
            name: 'How does the Logo Maker work?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: "We'll ask you a few questions about your business and style preferences. Then we’ll put to work our powerful AI technology to create logo designs that best fit your brand, based on a wide selection of hand-made designs."
            }
        },
        {
            '@type': 'Question',
            name: 'How long does it take to create my logo?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: 'Just a few moments! Our technology reduces the complex process of designing a logo for your business into a matter of seconds.'
            }
        },
        {
            '@type': 'Question',
            name: 'What do you mean by “logos made by talented designers on Fiverr”?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: 'All of the logo designs we suggest are based on the beautiful work of top designers on Fiverr, adjusted to your needs with the power of Artificial Intelligence (AI).'
            }
        },
        {
            '@type': 'Question',
            name: 'Can I use the Logo Maker for free?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: 'You can use the Logo Maker to create a logo and customize it at no cost. Once you have a logo you love, you can buy it by selecting one of our branding packages (starting from US$30) and instantly download the logo in any format you need.'
            }
        },
        {
            '@type': 'Question',
            name: 'What’s included in the logo branding packages? ',
            acceptedAnswer: {
                '@type': 'Answer',
                text: "You'll get your logo in different formats, sizes, and colors that work across all your digital assets. You can also get a social media kit and brand guidelines - depending on your business needs. <br> See our logo branding packages."
            }
        },
        {
            '@type': 'Question',
            name: 'I need help from a professional designer to customize my logo. What can I do?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: 'You can order customization services from the designer of your logo after buying the logo.'
            }
        },
        {
            '@type': 'Question',
            name: 'Where can I use my logo?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: "Anywhere you want! You'll get full commercial and non-commercial rights to use your logo anywhere you want to promote your business or brand."
            }
        },
        {
            '@type': 'Question',
            name: 'How to become a designer on Fiverr Logo Maker?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: "Logo Maker is not available to all freelance designers on Fiverr. Only the top Fiverr freelancers are chosen by the Editorial team, based on quality metrics. If you're already a freelancer on Fiverr and wish to start selling logos on Fiverr's Logo Maker, contact Customer Support. If you're not on Fiverr yet, join to start selling"
            }
        }
    ]
};

export const HOWTO_SCHEMA = {
    '@context': 'https://schema.org/',
    '@type': 'HowTo',
    name: 'How to make a logo',
    image: 'https://fiverr-res.cloudinary.com/w_1168,f_auto,q_auto,c_fill,g_center/v1/attachments/generic_asset/asset/5b2b30011e99290c6535e4886dd69511-1652351794820/logo-maker-landing-page-where-to-use.png',
    totalTime: 'PT5M',
    supply: [
        {
            '@type': 'HowToSupply',
            name: 'Creativity'
        },
        {
            '@type': 'HowToSupply',
            name: 'Brand Values'
        }
    ],
    tool: [
        {
            '@type': 'HowToTool',
            name: 'Internet'
        },
        {
            '@type': 'HowToTool',
            name: 'Computer'
        }
    ],
    step: [
        {
            '@type': 'HowToStep',
            position: 1,
            name: 'Tell us about your brand',
            image: 'https://fiverr-res.cloudinary.com/w_1336,f_auto,q_auto,c_fill,g_center/v1/attachments/generic_asset/asset/9fb705ffb84c6191cceba642e31209ca-1652446427013/tell-us-about-your-business-fiverr-logo-maker.png',
            itemListElement: {
                '@type': 'HowToDirection',
                text: 'Type in the name that you wish to display on your logo. You can also choose to add a tagline at this stage or later on, before completing your order, then select the name of your industry from the drop-down menu. For best results, select as many as 3 industries associated with your business.'
            }
        },
        {
            '@type': 'HowToStep',
            position: 2,
            name: 'Describe your brand’s personality',
            image: 'https://fiverr-res.cloudinary.com/w_1336,f_auto,q_auto,c_fill,g_center/v1/attachments/generic_asset/asset/b7a8792226ea84395b5ef4bb394f5885-1652446314203/define-look-and-feel-fiverr-logo-maker.png',
            itemListElement: {
                '@type': 'HowToDirection',
                text: 'Customize and share your style preferences and brand values to help the logo maker create a memorable customized logo for your brand.'
            }
        },
        {
            '@type': 'HowToStep',
            Position: 3,
            name: 'Let the algorithm work its magic',
            image: 'https://fiverr-res.cloudinary.com/w_1336,f_auto,q_auto,c_fill,g_center/v1/attachments/generic_asset/asset/e156da4ce9acc8be7268c5303da0bb1a-1652445182193/generate-logo-designs-fiverr-logo-maker.png',
            itemListElement: {
                '@type': 'HowToDirection',
                text: 'After submitting your brief, our algorithm will search for the most relevant logo designs for your needs. Once it’s done, you’ll receive a personalized selection of beautiful logo designs to choose from.'
            }
        },
        {
            '@type': 'HowToStep',
            Position: 4,
            name: 'Add your personal touch',
            image: 'https://fiverr-res.cloudinary.com/w_1336,f_auto,q_auto,c_fill,g_center/v1/attachments/generic_asset/asset/f9178d90ba449c24ef8bf9fa1cbf3ace-1652446458279/logo-design-editing-fiverr-logo-maker.png',
            itemListElement: {
                '@type': 'HowToDirection',
                text: 'Browse the different custom logo designs and choose yours as is, or customize the colors, layout, fonts, shapes and backgrounds, with our simple--to-use editing tool. You can even save your Logo and get back to it later at any time.'
            }
        },
        {
            '@type': 'HowToStep',
            Position: 5,
            name: 'Choose your Branding Package',
            image: 'https://fiverr-res.cloudinary.com/w_1336,f_auto,q_auto,c_fill,g_center/v1/attachments/generic_asset/asset/3f304d8b92ed17a02cf0f4f8c50ab602-1652446375249/download-your-logo-fiverr-logo-maker.png',
            itemListElement: {
                '@type': 'HowToDirection',
                text: 'Love your logo? Choose a branding package and download your logo with everything you need for your business - from brand guidelines to print quality files to a complete social media kit'
            }
        }
    ]
};

export const getRatingSchema = ({ ratingValue, ratingCount }) => ({
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: 'Fiverr Logo Maker',
    url: 'https://www.fiverr.com/logo-maker',
    Image: 'https://fiverr-res.cloudinary.com/w_1336,f_auto,q_auto,c_fill,g_center/v1/attachments/generic_asset/asset/b7a8792226ea84395b5ef4bb394f5885-1652446314203/define-look-and-feel-fiverr-logo-maker.png',
    description: 'An automated logo design tool provided by Fiverr.',
    brand: 'Fiverr',
    aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue,
        ratingCount,
        bestRating: '5',
        worstRating: '1'
    }
});
