import React from 'react';
import pathfinder from '@fiverr-private/pathfinder';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { Collapsible } from '@fiverr-private/fit';
import { useIsMobile } from '../../../../hooks';
import CloudinaryResponsiveImage from '../../../CloudinaryResponsiveImage';
import { CONTENT, IMAGE, IMAGE_RESOLUTIONS } from './constants';
import classes from './index.module.scss';

const WhereShouldYouUseYourLogo = () => {
    const isMobile = useIsMobile();

    return (
        <section className={classes.wrapper}>
            <div className={classes.thinContainer}>
                <h2 className={classes.title}>
                    <I18n k="makers_experience_perseus.buyer_landing_page.where_should_you_use.section_title"/>
                </h2>
                <p className={classes.subtitle}>
                    <I18n k="makers_experience_perseus.buyer_landing_page.where_should_you_use.section_subtitle_with_link"
                        templates={{
                            link: (text) => <a href={pathfinder('logo_maker_brand_solutions_page')}>{text}</a>
                        }}/>
                </p>
            </div>
            <div className={classes.content}>
                {isMobile && (
                    <div className={classes.collapsedView}>
                        {CONTENT.map(({ title, body, createLink }) => (
                            <Collapsible key={title}
                                className={classes.collapsible}
                                titleWrapperClassName={classes.collapsibleTitleWrapper}
                                title={
                                    <h3 className={classes.answerTitle}>
                                        <a href={createLink()}>
                                            <I18n k={title}/>
                                        </a>
                                    </h3>
                                }>
                                <p>
                                    <I18n k={body}/>
                                </p>
                            </Collapsible>
                        ))}
                    </div>
                )}
                {!isMobile && (
                    <div className={classes.nonCollapsedView}>
                        {CONTENT.map(({ title, body, createLink }) => (
                            <div key={title}>
                                <h3 className={classes.answerTitle}>
                                    <a href={createLink()}>
                                        <I18n k={title}/>
                                    </a>
                                </h3>
                                <p>
                                    <I18n k={body}/>
                                </p>
                            </div>
                        ))}
                    </div>
                )}
                <div className={classes.illustrationContainer}>
                    <CloudinaryResponsiveImage className={classes.illustrationImage}
                        width={IMAGE_RESOLUTIONS[0].config?.width}
                        height={IMAGE_RESOLUTIONS[0].config?.height}
                        image={IMAGE}
                        mediaQueryGrid={IMAGE_RESOLUTIONS}
                        alt={translate('makers_experience_perseus.buyer_landing_page.where_should_you_use.alt')}/>
                </div>
            </div>
        </section>
    );
};

export default WhereShouldYouUseYourLogo;
