import React from 'react';
import { arrayOf, shape, number } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { makersExperience } from '@fiverr-private/logo_maker_analytics/dist/esm/mixpanel';
import { IDEA_TAGS } from '../../../shared/components/LandingPages/IdeaTags/propTypes';
import { sendBiEventWithPosition } from '../../../shared/utils/sendBiEventWithPosition';
import { GET_STARTED_CTA_POSITIONS } from '../../../shared/constants';
import {
    HowDoesLogoMakerWork,
    ValuePoints,
    HowToCreateStandOutLogo,
    GetInspiredByLogos,
    IndustriesIdeas,
    Faq,
    WhyUseLogoMaker,
    AccentBanner,
    WhatUsersSay,
    TotalRatingSection,
    WhatMakesGoodLogo,
    HowToCreatePerfectLogo,
    BottomBanner,
    HowToMakeLogoVideo,
    WhereShouldYouUseYourLogo
} from '../../../shared/components/LandingPages/Sections';
import MakeMyLogoButton from '../../../shared/components/MakeMyLogoButton';
import monitor, { EVENT_TYPES } from '../../../service/bigquery';
import { useEffectOnce } from '../../../shared/hooks';
import { APP_NAME, getFAQContent } from './constants';
import IntroHeader from './IntroHeader';
import './index.module.scss';

const { reportGetStartedSubmitted, reportBlogArticleClicked, reportPageView } = makersExperience.buyerLandingPage;

const BuyerLandingPage = ({ industriesTags = [], reviewsStatistics }) => {
    useEffectOnce(() => {
        monitor.trackPageView();
        reportPageView();
    });

    const onBrandNameSubmit =
        (position) =>
            (brandName = '') => {
                sendBiEventWithPosition(position, EVENT_TYPES.GET_STARTED_BUYER_CLICKED);

                reportGetStartedSubmitted({
                    elementPosition: position,
                    brandName: brandName.trim()
                });
            };

    const onAccentBannerCtaClicked = (position) => () => {
        sendBiEventWithPosition(position, EVENT_TYPES.GET_STARTED_BUYER_CLICKED);

        reportGetStartedSubmitted({
            elementPosition: position
        });
    };

    const onBlogPostClicked = (title, position) => {
        reportBlogArticleClicked({
            title,
            horizontalPosition: position
        });
    };

    return (
        <>
            <IntroHeader onBrandNameSubmit={onBrandNameSubmit(GET_STARTED_CTA_POSITIONS.TOP)}
                rating={reviewsStatistics.rating}
                ratingsCount={reviewsStatistics.ratingsCount}
                source={APP_NAME}/>
            <ValuePoints/>
            <HowDoesLogoMakerWork/>
            <AccentBanner title={<I18n k="makers_experience_perseus.buyer_landing_page.accent_banner_top.section_title"/>}
                source={APP_NAME}
                onCtaClicked={onAccentBannerCtaClicked(GET_STARTED_CTA_POSITIONS.MIDDLE)}/>
            <HowToMakeLogoVideo/>
            <WhyUseLogoMaker/>
            <GetInspiredByLogos/>
            <WhatUsersSay/>
            <TotalRatingSection rating={reviewsStatistics.rating} ratingsCount={reviewsStatistics.ratingsCount}/>
            <IndustriesIdeas industries={industriesTags}/>
            <HowToCreateStandOutLogo/>
            <AccentBanner title={<I18n k="makers_experience_perseus.buyer_landing_page.accent_banner_bottom.section_title"/>}
                source={APP_NAME}
                onCtaClicked={onAccentBannerCtaClicked(GET_STARTED_CTA_POSITIONS.MIDDLE)}/>
            <WhatMakesGoodLogo/>
            <WhereShouldYouUseYourLogo/>
            <Faq content={getFAQContent()}/>
            <HowToCreatePerfectLogo onPostClick={onBlogPostClicked}/>
            <BottomBanner onBrandNameSubmit={onBrandNameSubmit(GET_STARTED_CTA_POSITIONS.BOTTOM)} source={APP_NAME}/>
            <MakeMyLogoButton/>
        </>
    );
};

BuyerLandingPage.propTypes = {
    industriesTags: arrayOf(IDEA_TAGS),
    reviewsStatistics: shape({
        rating: number,
        ratingsCount: number
    })
};

export default BuyerLandingPage;
