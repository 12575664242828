import { useRef } from 'react';

const useOnceBeforeRender = (callback) => {
    const hasRunRef = useRef(false);
    if (!hasRunRef.current) {
        hasRunRef.current = true;
        callback();
    }
};

export default useOnceBeforeRender;
