import pathfinder from '@fiverr-private/pathfinder';

const APP_NAME = 'buyer_landing_page';

const getFAQContent = () => [
    {
        question: 'makers_experience_perseus.buyer_landing_page.faq.question_1.question',
        answer: 'makers_experience_perseus.buyer_landing_page.faq.question_1.answer',
        link: pathfinder('logo_maker_tutorials_page')
    },
    {
        question: 'makers_experience_perseus.buyer_landing_page.faq.question_2.question',
        answer: 'makers_experience_perseus.buyer_landing_page.faq.question_2.answer'
    },
    {
        question: 'makers_experience_perseus.buyer_landing_page.faq.question_3.question',
        answer: 'makers_experience_perseus.buyer_landing_page.faq.question_3.answer'
    },
    {
        question: 'makers_experience_perseus.buyer_landing_page.faq.question_4.question',
        answer: 'makers_experience_perseus.buyer_landing_page.faq.question_4.answer',
        link: pathfinder('logo_maker_pricing_page')
    },
    {
        question: 'makers_experience_perseus.buyer_landing_page.faq.question_5.question',
        answer: 'makers_experience_perseus.buyer_landing_page.faq.question_5.answer',
        link: pathfinder('help_center_article', {
            article_id: '4662326669201-What-types-of-branding-packages-are-available'
        })
    },
    {
        question: 'makers_experience_perseus.buyer_landing_page.faq.question_6.question',
        answer: 'makers_experience_perseus.buyer_landing_page.faq.question_6.answer'
    },
    {
        question: 'makers_experience_perseus.buyer_landing_page.faq.question_7.question',
        answer: 'makers_experience_perseus.buyer_landing_page.faq.question_7.answer'
    },
    {
        question: 'makers_experience_perseus.buyer_landing_page.faq.question_8.question',
        answer: 'makers_experience_perseus.buyer_landing_page.faq.question_8.answer',
        link: pathfinder('start_selling')
    }
];

export { getFAQContent, APP_NAME };
