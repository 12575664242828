import { EXAMPLES_NAMES } from '../examples/constants';

const slidesConfig = [
    {
        name: EXAMPLES_NAMES.GAMING,
        seller: {
            username: 'skydesigner',
            profileImage:
                'https://fiverr-res.cloudinary.com/t_profile_small,q_auto,f_auto/attachments/profile/photo/79cf5c7a560e6668555338b2831480e1-1539770224917/2bb8af3c-4cce-42a8-a699-f11177524084.png'
        }
    },
    {
        name: EXAMPLES_NAMES.PIZZERIA,
        seller: {
            username: 'titus_media',
            profileImage:
                'https://fiverr-res.cloudinary.com/t_profile_small,q_auto,f_auto/attachments/profile/photo/d3be0c74e67b5959dcb0749709ab59f3-1548431354321/dec43a8f-3e95-4529-84c5-c35f6960bcb5.jpg'
        }
    },
    {
        name: EXAMPLES_NAMES.REAL_ESTATE,
        seller: {
            username: 'lironsho',
            profileImage:
                'https://fiverr-res.cloudinary.com/t_profile_small,q_auto,f_auto/attachments/profile/photo/e33a41ed6b3152710b1696f6edc0163c-1572949601299/1a2021fb-bf6d-41b2-aab5-0f52296f4dad.jpeg'
        }
    },
    {
        name: EXAMPLES_NAMES.BEAUTY,
        seller: {
            username: 'budaart',
            profileImage:
                'https://fiverr-res.cloudinary.com/t_profile_small,q_auto,f_auto/attachments/profile/photo/b7c6598c553c09455d31a72425d5dfe2-1569545409779/8c5cfce7-50b9-42d4-bbf6-23f5154b9b9d.jpg'
        }
    }
];

export default slidesConfig;
