import React from 'react';
import classnames from 'classnames';
import { string } from 'prop-types';
import { translate } from '@fiverr-private/i18n-react';
import CloudinaryResponsiveImage from '../../../../../shared/components/CloudinaryResponsiveImage';
import { EXAMPLES_IMAGES, IMAGE_RATIO } from './constants';
import './style.scss';

const mapRatioToSize = ({ width, height } = {}) =>
    IMAGE_RATIO.map(({ media, config }) => ({
        media,
        config: {
            width: Math.round(width * config.width),
            height: Math.round(height * config.height)
        }
    }));

const ExampleImages = ({ name }) => (
    <div className={classnames('header-example', `header-example-${name}`)}>
        {EXAMPLES_IMAGES[name].map((image = {}, i) => (
            <CloudinaryResponsiveImage key={i}
                image={image.src}
                width={image.size?.width}
                height={image.size?.height}
                mediaQueryGrid={mapRatioToSize(image.size)}
                alt={translate('makers_experience_perseus.general.logo_maker_example')}/>
        ))}
        {/* Square, image resizing simulation. Used to have height related to width */}
        <svg viewBox="0 0 10 10"/>
    </div>
);

ExampleImages.propTypes = {
    name: string
};

export default ExampleImages;
