import { useState, useLayoutEffect, useMemo } from 'react';
import { breakpoints } from '@fiverr-private/sass/helpers';
import { LOGOS } from '../../constants';

const getLogosLengthFromWidth = (width, maxSize) => {
    if (width <= breakpoints.sm) {
        return 6;
    } else if (width <= breakpoints.md) {
        return 9;
    } else {
        return maxSize;
    }
};

const useGetInspiredByLogos = () => {
    const [logosSize, setLogosSize] = useState();

    useLayoutEffect(() => {
        const listener = () => {
            setLogosSize(getLogosLengthFromWidth(window.innerWidth, LOGOS.length));
        };
        listener();
        window.addEventListener('resize', listener);
        return () => {
            window.removeEventListener('resize', listener);
        };
    }, []);

    const cuttedLogos = useMemo(() => LOGOS.slice(0, logosSize), [logosSize]);

    return {
        cuttedLogos
    };
};

export default useGetInspiredByLogos;
