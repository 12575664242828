import React, { useMemo } from 'react';
import { string, func } from 'prop-types';
import { noop } from 'lodash';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/fit';
import { useBriefIdContext } from '../../../BriefIdContext';
import { getLogoNameBriefStepHref } from '../../../../utils/getLogoNameBriefStepHref';
import classes from './index.module.scss';

const AccentBanner = ({ title, source, onCtaClicked = noop }) => {
    const briefId = useBriefIdContext();

    const briefHref = useMemo(() => getLogoNameBriefStepHref({ source, briefId }), [source, briefId]);

    return (
        <section className={classes.wrapper}>
            <div className={classes.container}>
                <h2 className={classes.title}>{title}</h2>
                <Button className={classes.button}
                    color={Button.COLORS.WHITE}
                    textColor={Button.TEXT_COLORS.DARK_GREY}
                    fill={Button.FILLS.FULL}
                    size={Button.SIZES.LARGE}
                    href={briefHref}
                    onClick={onCtaClicked}>
                    <I18n k="makers_experience_perseus.general.start_now"/>
                </Button>
            </div>
        </section>
    );
};

AccentBanner.propTypes = {
    title: string,
    source: string,
    onCtaClicked: func
};

export default AccentBanner;
