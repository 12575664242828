import React from 'react';
import { number } from 'prop-types';
import TotalRating from '../../TotalRating';
import classes from './index.module.scss';

/**
 * @param {{
 *  rating?: number,
 *  ratingsCount?: number;
 * }} props
 */
const TotalRatingSection = ({ rating, ratingsCount }) => (
    <section className={classes.wrapper}>
        <div className={classes.container}>
            <TotalRating rating={rating} ratingsCount={ratingsCount} className={classes.rating}/>
        </div>
    </section>
);

TotalRatingSection.propTypes = {
    rating: number,
    ratingsCount: number
};

export default TotalRatingSection;
