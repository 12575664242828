import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointSm } from '@fiverr-private/sass/helpers';
import { useUserAgentContext } from '../../components/UserAgentContext';
import type { UserAgentData } from '../../lib/getUserAgent';

export const useIsMobile = () => {
    const userAgent = useUserAgentContext() as UserAgentData;
    const isNotMobileSize = useMediaQuery({
        query: `(min-width: ${breakpointSm}px)`
    });
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(isNotMobileSize === undefined ? userAgent.isMobile : !isNotMobileSize);
    }, [userAgent, isNotMobileSize]);

    return isMobile;
};
