import React from 'react';
import { func } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { Icon } from '@fiverr-private/fit';
import { l_arrow_right } from '@fiverr-private/icons';
import CloudinaryResponsiveImage from '../../../../CloudinaryResponsiveImage';
import { IMAGES_RESOLUTIONS } from './constants';
import classes from './index.module.scss';

const PostCard = ({ onClick, ...post }) => (
    <div className={classes.wrapper}>
        <a href={post.link} target="_blank" onClick={onClick} rel="noreferrer">
            <CloudinaryResponsiveImage className={classes.image}
                image={post.image}
                mediaQueryGrid={IMAGES_RESOLUTIONS}
                alt={post.alt}
                width={post.size?.width}
                height={post.size?.height}/>
        </a>
        <a className={classes.titleLink} href={post.link} target="_blank" onClick={onClick} rel="noreferrer">
            <h3 className={classes.title}>{post.title}</h3>
        </a>
        <p className={classes.text}>{post.excerpt}</p>
        <a className={classes.link} href={post.link} target="_blank" onClick={onClick} rel="noreferrer">
            <I18n k="makers_experience_perseus.general.read_more"/>
            <Icon className={classes.linkIcon}>{l_arrow_right}</Icon>
        </a>
    </div>
);

PostCard.propTypes = {
    onClick: func
};

export default PostCard;
