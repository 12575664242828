import React from 'react';
import { array, func, number } from 'prop-types';
import cn from 'classnames';
import { noop } from 'lodash';
import { Button, Icon, Input } from '@fiverr-private/fit';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { l_arrow_left } from '@fiverr-private/icons';
import { HERO_SECTION_ID } from '@fiverr-private/variations_display/src/utils/getHeroOffset/constants';
import useHero from './hooks/useHero';
import { DEFAULT_INTERVAL, TRANSITION_DURATION } from './constants';
import classes from './index.module.scss';

const Hero = ({ slides, interval = DEFAULT_INTERVAL, onBrandNameSubmit = noop }) => {
    const { slide, isInTransition, onEnter, onSubmit, onScrollDown, wrapperRef, inputRef, isDarkTheme } = useHero({
        slides,
        interval,
        onBrandNameSubmit,
        transitionDuration: TRANSITION_DURATION
    });

    return (
        <section className={cn(classes.wrapper, isDarkTheme ? classes.dark : classes.light)}
            style={{ backgroundColor: slide.backgroundColor }}
            ref={wrapperRef}
            id={HERO_SECTION_ID}>
            <div className={classes.container}>
                <div className={classes.textWrapper}>
                    <h1 className={classes.title}>
                        <I18n k="makers_experience_perseus.buyer_landing_page.header.page_sub_title"/>
                    </h1>
                    <Input className={classes.input}
                        inputClassName={classes.innerInput}
                        placeholder={translate('makers_experience_perseus.buyer_landing_page.header.input_placeholder')}
                        onKeyPress={onEnter}
                        ref={inputRef}
                        aria-label={translate('makers_experience_perseus.buyer_landing_page.header.input_placeholder')}/>
                    <Button onClick={onSubmit} className={classes.button} size={Button.SIZES.LARGE}>
                        <I18n k="makers_experience_perseus.general.make_your_logo"/>
                    </Button>
                </div>
                <div className={classes.imageWrapper}>
                    <img src={slide.image}
                        className={cn(classes.image, { [classes.inTransition]: isInTransition })}
                        style={{ transitionDuration: `${TRANSITION_DURATION}ms` }}/>
                </div>
            </div>
            <div className={classes.scrollButton} aria-label="Scroll down" role="button" onClick={onScrollDown}>
                <Icon className={classes.downIcon}>{l_arrow_left}</Icon>
            </div>
        </section>
    );
};

Hero.propTypes = {
    slides: array,
    interval: number,
    onBrandNameSubmit: func
};

export default Hero;
